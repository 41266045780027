.loggedInContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.title {
  margin-left: 12px;
  margin-right: 12px;
}

.wordMarkPadding {
  margin-top: 4px;
  margin-left: 8px;
  margin-right: 32px;
}

.menubar {
  background-color: "var(--surface-0)";
  padding-left: 20px;
  padding-right: 20px;
}

@media print {
  .menubar {
    display: none;
  }
}
