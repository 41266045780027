.inputText {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 20px;
}

.dropdown {
  width: 100%;
  margin-bottom: 20px;
}

.listingTypeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.listingType {
  flex-shrink: 0;
  min-width: 144px;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 16px;

  display: flex;
  gap: 8px;
  align-items: center;
}

.listingTypeContainerSingle {
  margin-top: 32px;
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
}
