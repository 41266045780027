.container {
  gap: 8px;
  display: flex;
  align-items: center;
  height: 28px;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-color);
}

.icon {
  width: 20px;
  height: 20px;
}
