.riskDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.riskSignalsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 16px;
}

.riskSignalCard {
  background-color: var(--surface-100);
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 6px;
  padding: 12px;
  width: 300px;
  height: 130px;
  flex-shrink: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

@media print {
  .riskSignalCard {
    box-shadow: none;
  }
}
