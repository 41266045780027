.container {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 48px;
  align-items: center;
  gap: 48px;
}

.cardContainer {
  display: flex;
  gap: 60px;
}

.headerText {
  font-size: 36px;
  letter-spacing: 3.5px;
  font-family: var(--font-family);
}
