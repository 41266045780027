.topContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-height: 0;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.listingsContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  padding: 12px;
  gap: 16px;
  background-color: rgba(100, 100, 100, 0.15);
  backdrop-filter: blur(3px);
  align-items: center;
  overflow-y: auto;
  border: 1px solid var(--surface-border);
}

.submitButton {
  width: 100%;
}

.card {
  width: 500px;
}

.selectButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}

.renewalContainer {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
