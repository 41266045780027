.container {
  display: flex;
  flex-direction: column;
  margin: 32px;
  align-items: center;
  min-height: 0px;
  overflow-y: auto;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 600px);
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.analyticsCard {
  width: 600px;
  height: 360px;
}
