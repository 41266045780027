.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9d9d90a;
  border-radius: 20px;
  margin-top: 102.5px;
  padding-top: 124px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 209px;
  height: 262px;
  position: relative;
  gap: 4px;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
}

.imgContainer {
  width: 205px;
  height: 205px;
  border-radius: 102.5px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.75);
  object-fit: cover;
  position: absolute;
  top: -102.5px;
}

.nameText {
  font-family: var(--font-family);
  font-size: 16px;
  letter-spacing: 1.5px;
}

.titleText {
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--primary-color);
  letter-spacing: 1.5px;
}

.infoText {
  font-family: var(--font-family);
  font-size: 11px;
}

.infoTextContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.infoTextRow {
  display: flex;
  gap: 4px;
}
