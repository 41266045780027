.specialtyRow {
  min-width: 0;
  flex-shrink: 0;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  font-family: var(--font-family);
  background-color: var(--surface-card);
  transition: border-radius 0.15s ease, box-shadow 0.15s ease,
    background-color 0.15s ease;
  color: var(--text-color);
}

.specialtyRow:hover,
.specialtyRowHover {
  border-radius: 4px;
  background-color: var(--surface-100);
}

.rowContentSmall {
  width: 40px;
  display: flex;
  align-items: center;
}

.rowContent {
  width: 160px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.knobText {
  color: var(--primary-color);
  font-weight: 600;
}

.signalContainer {
  display: flex;
  gap: 2px;
}

.twoLineText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
