.searchContainer {
  display: flex;
  flex-direction: column;
  margin: 32px;
  align-items: center;
  gap: 8px;
  min-height: 0px;
}

.searchInput {
  width: 800px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-right: 20px;
  flex-shrink: 0;
}

.rowsContainer {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 1260px;
  min-height: 200px;
  flex-grow: 1;
  border-radius: 6px;
  overflow-y: auto;
}

.subSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
}

.filterSortContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.chipContainer {
  display: flex;
  gap: 8px;
}

.chipButton {
  background-color: var(--surface-0);
  height: 38px;
  border-radius: 19px;
  color: var(--text-color-secondary);
  border-color: var(--surface-100);
  font-size: 14px;
}

.multiSelect {
  width: 180px;
  height: 40px;
  border-radius: 20px;
  font-size: 13px;
  color: var(--text-color-secondary);
}

.multiSelect > span {
  font-size: 13px;
  color: var(--text-color-secondary);
}
