.printContainer {
  margin-top: 32px;
  display: none;
  flex-direction: column;
  gap: 24px;
  color: rgb(77, 85, 98);
  font-family: var(--font-family);
  print-color-adjust: exact;
}

.pageBreakAvoid {
  page-break-inside: avoid;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  margin: 0px;
}

@media print {
  .printContainer {
    display: flex;
  }
}
