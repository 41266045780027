.container {
  flex-direction: column;
  display: flex;
  gap: 40px;
}

.headerText {
  margin: 0px;
}

.markupContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.barGraphContainer {
  display: flex;
  flex-direction: column;
}

.barGraphComponent {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.barRowContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.ratingContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-color: var(--surface-100);
  border: 1px solid var(--surface-border);
  width: 160px;
  padding: 12px;
  font-size: 14px;
}

.rangeContainer {
  display: flex;
  flex-direction: column;
  width: 16px;
  align-items: center;
  justify-content: space-between;
}

.rangeText {
  font-size: 11px;
  color: var(--text-color-secondary);
}

.badge {
  position: absolute;
  top: -6px;
  right: -6px;
}
