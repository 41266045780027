.app {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.backgroundImg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media print {
  .backgroundImg {
    display: none;
  }

  .app {
    height: auto;
  }
}

.versionText {
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 10px;
  font-family: var(--font-family);
  color: var(--text-color-secondary);
}
