.gridContainer {
  display: grid;
  grid-template-rows: repeat(11, 1fr); /* 11 equal rows */
  grid-template-columns: repeat(6, 1fr); /* 6 equal columns */
  gap: 4px; /* Optional: space between grid items */
  width: 1200px; /* Set a fixed width for the grid */
  height: 600px; /* Set a fixed height for the grid */
}

.gridItem {
  background-color: var(--surface-100);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 4px 8px;
}

.gridItemTopLeft {
  background-color: var(--blue-200);
}

.gridItemLeftCol {
  background-color: var(--blue-100);
}

.gridItemTopLeftDark {
  background-color: var(--blue-800);
}

.gridItemLeftColDark {
  background-color: var(--blue-900);
}

.gridItemBold {
  font-weight: bold;
}

.gridItemAppetiteHeader {
  background-color: var(--surface-200);
  padding: 8px;
}

.gridItemSelected {
  z-index: 10;
  border: 1px solid var(--primary-color);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  color: var(--primary-color);
}
