.financialInfoContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.financialInfoRow {
  display: flex;
  gap: 8px;
  align-items: center;
}

.financialInfoHeader {
  font-size: 16px;
  min-width: 80px;
  font-weight: bold;
}

.roundedContainer {
  padding: 12px 16px;
  background-color: var(--surface-100);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: solid 1px var(--surface-200);
  cursor: pointer;
}

.roundedContainer:hover {
  z-index: 10;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.containerText {
  font-size: 12px;
}

.boldNumber {
  font-weight: bold;
}

.reasonsContainer {
  margin-top: 8px;
  margin-left: 88px;
  display: flex;
  flex-wrap: wrap;

  gap: 8px;
}

.roundedContainerSmall {
  padding: 8px 12px;
  background-color: var(--surface-100);
  border-radius: 6px;
  border: solid 1px var(--surface-200);
  align-self: flex-start;
}
