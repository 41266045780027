.companyInfoContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  font-weight: 600;
  min-height: 40px;
}

.companyInfoComponentContainer {
  display: flex;
  gap: 6px;
}
