.container {
  flex-grow: 1;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerTextImage {
  width: 1000px;
  margin-bottom: 80px;
}
