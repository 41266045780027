.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.headerText {
  font-size: 36px;
  letter-spacing: 3.5px;
  font-family: var(--font-family);
  color: var(--text-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.panel {
  width: 800px;
}

.infoTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoTextRow {
  display: flex;
  gap: 8px;
}
