.splashContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.splashCard {
  max-width: 400px;
}

.inputText {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 20px;
}

.submitButton {
  width: 100%;
}
