.iconContainer {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 8px;
  border: solid 1px transparent;
  color: var(--text-color);
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
