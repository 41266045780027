.inputContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-width: 220px;
}

.inputsContainer {
  display: flex;
  gap: 24px;
}

.inputLabel {
  font-weight: bold;
}

.mathRow {
  padding: 4px 16px;
  display: flex;
  gap: 16px;
}

.mathColumn {
  flex: 1;
  max-width: 350px;
}
