.crimeGrid {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.gridItem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.crimeHeader {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
}

.crimeHeaderText {
  font-size: 16px;
  font-weight: bold;
  min-width: 124px;
}

.roundedContainer {
  padding: 12px 16px;
  background-color: var(--surface-100);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--surface-200);
  flex-direction: column;
  gap: 4px;
}

.crimeTitle {
  font-size: 18px;
  min-width: 156px;
}

.boldNumber {
  font-weight: bold;
}

.containerText {
  font-size: 12px;
}
