.container {
  display: flex;
  flex-grow: 1;
  min-height: 0;
}

.sidebar {
  width: 340px;
  height: 100%;
  padding: 32px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}

.statusContainer {
  background-color: var(--surface-card);
  border-radius: 4px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 12px;

  font-family: var(--font-family);
  color: var(--text-color);
}

.statusContainerInner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
  flex-grow: 1;
}

.statusRow {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.downloadButton {
  height: 32px;
  width: 32px;
  flex-grow: 0;
}

.cardContainer {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  overflow-y: auto;
}

.fileUpload {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
